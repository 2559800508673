<template>
  <section>
    <title-section
      :title="modeEdit ? 'Editar producto' : 'Detalle del producto'"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
      <template v-if="!loading">
        <template v-if="!modeEdit">
          <div class="level-item">
            <button
              class="button button_micro_dark btn_edit"
              @click="modeEdit = true"
            >
              EDITAR PRODUCTO
            </button>
          </div>
        </template>
        <template v-else>
          <div class="level-item">
            <button class="button button_micro_dark_outlined" @click="resetProduct" :disabled="loadingBtn">
              CANCELAR
            </button>
          </div>
          <div class="level-item">
            <button
              class="button button_micro_dark"
              :disabled="loadingBtn"
              :class="{ 'is-loading' : loadingBtn }"
              @click="updateProductAction"
            >
              ACTUALIZAR
            </button>
          </div>
        </template>
      </template>
    </title-section>
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <template v-if="hasError">
        <div class="hero-body">
          <div class="container">
            <base-not-data
              title="No hay datos para mostrar"
            >
            </base-not-data>
          </div>
        </div>
      </template>
      <template v-else>
        <product-main-detail
          ref="productMainDetailRef"
          :modeEdit="modeEdit"
          :loadingCatalog="loadingCatalog"
          :productDetail="productDetail"
          :brandListParent="brandList"
          :sectorsListParent="sectorsList"
          :linesListParent="linesList"
          :currencyListParent="currencyList"
        />
      </template>
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import utilities from '@/extras/utilities'

export default {
  name: 'ProductsDetail',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    ProductMainDetail: () => import('@/components/Products/ProductMainDetail.vue'),
    BaseNotData: () => import('@/components/UI/BaseNotData.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      loading: false,
      loadingCatalog: false,
      modeEdit: false,
      productId: null,
      brandList: [],
      sectorsList: [],
      linesList: [],
      currencyList: [],
      backupProductDetal: null,
      hasError: false,
      loadingBtn: false,
      productDetail: {
        id: 1,
        product_name: null,
        brandId: null,
        brand_name: null,
        catalogue: null,
        key_mi: null,
        active: null,
        introduction: null,
        description: null,
        image_data_sheet: null,
        delivery_time: null,
        available_only_mexico: null,
        enable_add_cart: false,
        lines: [],
        sectors: [],
        keywords: '',
        image_main: null,
        image_one: null,
        image_two: null,
        more_specs: null,
        product_variables: null,
        currency_id: null,
        currency_name: null,
        cost: null,
        promotion_discount: null,
        available_products: null,
        pdf_product_variation: null,
        name_product_variation: null,
        tables: []
      }
    }
  },
  methods: {
    ...mapActions([
      'returnPrevView',
      'getProductDetail',
      'getProductBrandSelect',
      'getProductSectorSelect',
      'getCurrency',
      'getProductLineWithSublines',
      'updateProduct',
      'addTemporalNotification'
    ]),
    async getProductDetailAction () {
      const { productDetail } = await this.getProductDetail(this.productId)
      if (!productDetail) {
        this.hasError = true
      } else {
        this.backupProductDetal = productDetail
        await this.spreadData(this.backupProductDetal)
      }
      this.loading = false
    },
    async spreadData (data) {
      this.productDetail.id = data.products.id
      this.productDetail.product_name = data.products.product_name
      this.productDetail.brandId = data.products.brandId
      this.productDetail.brand_name = data.products.brand_name
      this.productDetail.catalogue = data.products.catalogue
      this.productDetail.key_mi = data.products.key_mi
      this.productDetail.active = data.products.active
      this.productDetail.introduction = data.products.introduction
      this.productDetail.description = data.products.description
      this.productDetail.image_data_sheet = data.products.image_data_sheet
      this.productDetail.delivery_time = data.products.delivery_time
      this.productDetail.available_only_mexico = data.products.available_only_mexico
      this.productDetail.enable_add_cart = data.products.enable_add_cart
      this.productDetail.image_main = data.products.image_main
      this.productDetail.image_one = data.products.image_one
      this.productDetail.image_two = data.products.image_two
      this.productDetail.more_specs = data.products.more_specs
      this.productDetail.product_variables = data.products.product_variables
      this.productDetail.currency_id = data.products.currency_id
      this.productDetail.currency_name = data.products.currency_name
      this.productDetail.cost = data.products.cost
      this.productDetail.promotion_discount = data.products.promotion_discount
      this.productDetail.available_products = data.products.total_available_products
      this.productDetail.request_demo = data.products.request_demo
      this.productDetail.pdf_product_variation = data.products.pdf_product_variation
      this.productDetail.name_product_variation = data.products.pdf_product_variation
      this.productDetail.lines = data.productLines
      this.productDetail.sectors = data.sectors
      this.productDetail.keywords = data.products.keywords
      this.productDetail.tables = data.tablesVariable
      this.productDetail.relatedProducts = utilities.objectValidate(data, 'relatedProducts', [])
    },
    async resetProduct () {
      await this.$refs.productMainDetailRef.spreadData()
      this.modeEdit = false
    },
    async updateProductAction () {
      this.loadingBtn = true
      const dataProduct = await this.$refs.productMainDetailRef.getDataProduct()
      if (dataProduct) {
        const resp = await this.updateProduct(dataProduct)
        if (resp) {
          this.loading = true
          await this.getProductDetailAction()
          this.modeEdit = false
        }
      } else this.addTemporalNotification({ type: 'danger', message: 'Ingrese los campos requeridos' })
      this.loadingBtn = false
    }
  },
  async beforeMount () {
    this.loadingCatalog = true
    this.productId = await this.$route.params.productId
    this.loading = true

    const dataBrands = await this.getProductBrandSelect()
    if (dataBrands.success) {
      this.brandList = dataBrands.brands
    }
    const dataSectors = await this.getProductSectorSelect()
    if (dataSectors.success) {
      this.sectorsList = dataSectors.sectors
    }
    const dataLines = await this.getProductLineWithSublines()
    if (dataLines.success) {
      this.linesList = dataLines.lines
    }
    const dataCurrency = await this.getCurrency()
    if (dataCurrency.success) {
      this.currencyList = dataCurrency.currency
    }

    await this.getProductDetailAction()
    this.loadingCatalog = false
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
  .button.btn_edit {
    width: auto;
  }
</style>
